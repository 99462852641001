import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import getImageSource from './helpers/getImageSource';

import './icon.scss';

function Icon(props) {
    const image = getImageSource(props.kind);
    const materialClassNames = classnames('material-icons icon', {
        'icon--inline': props.inline,
        [`text-${props.color}`]: props.color,
    }, [props.className]);

    if (image) {
        return (
            <img
                className={`icon-img ${props.className}`}
                alt="icon"
                style={{ width: `${props.fontSize}px` }}
                src={image}
            />
        );
    }

    return (
        <i
            className={materialClassNames}
            style={{ fontSize: props.fontSize }}
        >
            { props.kind }
        </i>
    );
}

Icon.propTypes = {
    kind: PropTypes.string.isRequired,
    className: PropTypes.string,
    inline: PropTypes.bool,
    color: PropTypes.string,
    fontSize: PropTypes.number,
};

Icon.defaultProps = {
    inline: false,
    className: '',
    color: null,
    fontSize: 24,
};

export default Icon;
