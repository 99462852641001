import { addDays, isBefore, set } from 'date-fns'
import React, { ChangeEvent, FC, FormEvent, ReactElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import trans from '../../../helpers/trans'
import { Button, InfoListBlock, InfoListItem, Input, LoadingSpinner, RadioButtonsList } from '../../../components'
import { DateInput, TimeInput } from '../../../compositions'
import { LeaveOfAbsenceForm } from '../../../entities/LeaveOfAbsences/LeaveOfAbsences'
import { ConnectedSpecialDaysList } from '../../../connectors'

import './AddLeaveOfAbsenceForm.scss'

interface AddLeaveOfAbsenceFormProps {
    isLoading?: boolean;
    startDate: Date;
    endDate: Date;
    onSubmit: (formData: LeaveOfAbsenceForm) => void;
    className?: string;
}

const AddLeaveOfAbsenceForm: FC<AddLeaveOfAbsenceFormProps> = ({
    isLoading = false,
    startDate: formStartDate,
    endDate: formEndDate,
    onSubmit,
    className = '',
}): ReactElement => {
    const [startDate, setStartDate] = useState<Date>(formStartDate)
    const [endDate, setEndDate] = useState<Date>(formEndDate)
    const [startTime, setStartTime] = useState<string>('09:00')
    const [endTime, setEndTime] = useState<string>('18:00')

    const [startDateTime, setStartDateTime] = useState<Date>()
    const [endDateTime, setEndDateTime] = useState<Date>()

    const [reason, setReason] = useState<string>('')
    const [dateRangeValid, setDateRangeValid] = useState<boolean>(true)
    const [multipleDays, setMultipleDays] = useState(false)

    const history = useHistory()

    const canSubmit = dateRangeValid && reason.length > 0

    const handleFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        onSubmit({
            startDate: startDateTime!,
            endDate: endDateTime!,
            reason,
        })

        history.push(`/${trans('nav.absences.link')}/${trans('nav.leaveOfAbsenceRequests.link')}`)
    }

    const handleReasonChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setReason(event.target.value)
    }

    const toggleMultipleDays = (): void => {
        setMultipleDays(! multipleDays)
    }

    useEffect(() => {
        if (multipleDays) {
            setStartDateTime(set(startDate, { hours: 4, minutes: 0 }))
            setEndDateTime(set(addDays(endDate, 1), { hours: 3, minutes: 59, seconds: 58 }))
        } else {
            setStartDateTime(set(
                startDate,
                { hours: +startTime.split(':')[0], minutes: +startTime.split(':')[1] },
            ))
            setEndDateTime(set(
                startDate,
                { hours: +endTime.split(':')[0], minutes: +endTime.split(':')[1] },
            ))
        }
    }, [startDate, startTime, endDate, endTime, multipleDays])

    useEffect(() => {
        if (startDateTime && endDateTime) {
            setDateRangeValid(isBefore(startDateTime, endDateTime))
        }
    }, [startDateTime, endDateTime])

    if (isLoading) {
        return <LoadingSpinner />
    }

    return (
        <form
            onSubmit={handleFormSubmit}
            className={`add-leave-of-absence-form ${className}`}
        >
            <RadioButtonsList
                className="sadd-leave-of-absence-form__multiple-days-radio-list"
                checked={multipleDays ? 1 : 0}
                buttons={[
                    { label: trans('containers.leaveOfAbsenceDetailContainer.singleDay') },
                    { label: trans('containers.leaveOfAbsenceDetailContainer.multipleDays') },
                ]}
                onRadioChange={toggleMultipleDays}
            />

            <ul className="add-leave-of-absence-form__list">

                {! multipleDays && (
                    <>
                        <InfoListItem
                            label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceSingleDate')}
                            className="add-leave-of-absence-form__list-item"
                        >
                            <DateInput
                                hideLabel
                                label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceSingleDate')}
                                type="date"
                                value={startDate}
                                onChange={setStartDate}
                            />
                        </InfoListItem>

                        <InfoListItem
                            label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceStartTime')}
                            className="add-leave-of-absence-form__list-item"
                        >
                            <TimeInput
                                hideLabel
                                label={trans('common.from')}
                                value={startTime}
                                onChange={setStartTime}
                                className="add-leave-of-absence-form__time-input"
                            />
                        </InfoListItem>

                        <InfoListItem
                            label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceEndTime')}
                            className="add-leave-of-absence-form__list-item"
                        >
                            <TimeInput
                                hideLabel
                                label={trans('common.from')}
                                value={endTime}
                                onChange={setEndTime}
                                className="add-leave-of-absence-form__time-input"
                            />
                        </InfoListItem>
                    </>
                )}

                {multipleDays && (
                    <>
                        <InfoListItem
                            label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceStartDate')}
                            className="add-leave-of-absence-form__list-item"
                        >
                            <DateInput
                                hideLabel
                                label={trans('common.from')}
                                type="date"
                                value={startDate}
                                onChange={setStartDate}
                            />
                        </InfoListItem>

                        <InfoListItem
                            label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceEndDate')}
                            className="add-leave-of-absence-form__list-item"
                        >
                            <DateInput
                                hideLabel
                                label={trans('common.from')}
                                type="date"
                                value={endDate}
                                onChange={setEndDate}
                            />
                        </InfoListItem>
                    </>
                )}

                {(startDateTime && endDateTime) && (
                    <ConnectedSpecialDaysList
                        startDate={startDateTime}
                        endDate={endDateTime}
                    />
                )}

                <InfoListBlock
                    label={trans('containers.leaveOfAbsenceDetailContainer.leaveOfAbsenceReason')}
                    className="add-leave-of-absence-form__list-block"
                >
                    <Input
                        onChange={handleReasonChange}
                        placeholder={trans('common.descriptionOfThisRequest')}
                        className="add-leave-of-absence-form__textarea-input"
                    />
                </InfoListBlock>
            </ul>

            <Button
                disabled={! canSubmit}
                type="submit"
                className="add-leave-of-absence-form__submit-button"
                text={trans('common.request')}
            />
        </form>
    )
}

export default AddLeaveOfAbsenceForm
