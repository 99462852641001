// @ts-expect-error path
import briefcase from '../../../../images/briefcase.svg';
// @ts-expect-error path
import cloud from '../../../../images/cloud.svg';
// @ts-expect-error path
import flag from '../../../../images/flag.svg';
// @ts-expect-error path
import group from '../../../../images/group.svg';
// @ts-expect-error path
import palm from '../../../../images/palm.svg';
// @ts-expect-error path
import party from '../../../../images/party.svg';
// @ts-expect-error path
import person from '../../../../images/person.svg';
// @ts-expect-error path
import personBlack from '../../../../images/person-black.svg';
// @ts-expect-error path
import sun from '../../../../images/sun.svg';

const getImageSource = (kind: string): string | undefined => {
    /* eslint-disable indent */
    switch (kind) {
        case 'crowded':
            return cloud;
        case 'warm-weather':
            return palm;
        case 'party':
            return party;
        case 'holiday':
            return sun;
        case 'christmas':
            return group;
        case 'national-holiday':
            return flag;
        case 'business':
            return briefcase;
        case 'person':
            return person;
        case 'person-black':
            return personBlack;
        default:
            return;
    }
    /* eslint-enable indent */
};

export default getImageSource;
