import { addDays, differenceInDays, formatDate } from '../../helpers/date'
import { DateFormat } from '../../types/dateFormatTypes'
import { translate } from '../../../helpers/translations/translator'
import { convertDateToDateYearString } from '../../../helpers/DateTimeParser'
import { format } from 'date-fns'

export const getLeaveOfAbsencesDateLabel = (start: Date, end?: Date): string => {
    let formattedStartDate = convertDateToDateYearString(start);

    // Values between 3:59:58 and 4:00:00 are considered full day
    const isFullDay = start.getSeconds() > 0 || start.getHours() === 4;

    if (! isFullDay) {
        formattedStartDate = `${formattedStartDate} ${format(start, 'HH:mm')}`;
    }

    if (!end) {
        return `${translate('pages.absences.from')} ${formattedStartDate}`;
    }

    const daysBetween = differenceInDays(end, start);

    if (daysBetween === 0) {
        if (!isFullDay) {
            const isEndFullDay = end && (end.getSeconds() > 0 || end.getHours() === 4);
            const formattedEndTime = isEndFullDay ? '4:00' : format(end, 'HH:mm')
            return `${formattedStartDate} - ${formattedEndTime}`;
        }

        return formattedStartDate;
    }

    let formattedEndDate = convertDateToDateYearString(addDays(start, daysBetween));

    if (! isFullDay) {
        formattedEndDate = `${formattedEndDate} ${format(end, 'HH:mm')}`;
    }

    return `${formattedStartDate} ${translate('pages.absences.until')} ${formattedEndDate}`;
};

export const getLeaveOfAbsencesDateLabelShort = (date?: Date): string => {
    return formatDate(date!, DateFormat.dateWithStandAloneMonthShort);
};
