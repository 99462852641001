import pacoAbsenceReducer, { AbsencesState } from './absences/absencesReducer';
import pacoLeaveOfAbsencesRequestsReducer, { LeaveOfAbsencesRequestsState } from './leaveOfAbsencesRequests/leaveOfAbsencesRequestsReducer';
import pacoLeaveOfAbsenceRequestDetailReducer, { LeaveOfAbsenceRequestDetailState } from './leaveOfAbsenceRequestDetail/leaveOfAbsenceRequestDetailReducer';
import calendarReducer, { CalendarState } from './@interface/calendar/calendarReducer';
import pacoAppReducer, { AppState } from './app/appReducer';
import pacoRegistrationReducer, { RegistrationState } from './registration/registrationReducer';
import pacoShiftsReducer, { ShiftsState } from './shifts/shiftsReducer';
import pacoSpecialDaysReducer, { SpecialDaysState } from './specialDays/specialDaysReducer';
import toastsReducer, { ToastsState } from './toasts/toastsReducer';
import yearEndStatementReducer, { YearEndStatementsState } from './yearEndStatement/yearEndStatementReducer';

export interface Reducers {
    calendarReducer: CalendarState;
    pacoAppReducer: AppState;
    pacoRegistrationReducer: RegistrationState;
    pacoShiftsReducer: ShiftsState;
    pacoSpecialDaysReducer: SpecialDaysState;
    toastsReducer: ToastsState;
    yearEndStatementReducer: YearEndStatementsState;
    pacoAbsenceReducer: AbsencesState;
    pacoLeaveOfAbsencesRequestsReducer: LeaveOfAbsencesRequestsState;
    pacoLeaveOfAbsenceRequestDetailReducer: LeaveOfAbsenceRequestDetailState;
}

export default {
    calendarReducer,
    pacoAppReducer,
    pacoRegistrationReducer,
    pacoShiftsReducer,
    pacoSpecialDaysReducer,
    toastsReducer,
    yearEndStatementReducer,
    pacoAbsenceReducer,
    pacoLeaveOfAbsencesRequestsReducer,
    pacoLeaveOfAbsenceRequestDetailReducer,
};
