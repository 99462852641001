import React, {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import { addDays, addMinutes, differenceInDays, startOfDay } from 'date-fns';

import { SpecialDaysList } from '../../containers';
import { fetchSpecialDays } from '../../redux/specialDays/specialDaysActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedSpecialDaysListProps {
    startDate: Date;
    endDate: Date;
    className?: string;
}

export const ConnectedSpecialDaysList: FC<ConnectedSpecialDaysListProps> = ({
    startDate,
    endDate,
    className = '',
}): ReactElement | null => {
    const prevDatesRef = useRef<{ startDate: Date; endDate: Date } | null>(null);

    const dispatch = useTypedDispatch();
    const { specialDays, isLoading } = useTypedSelector(state => state.pacoSpecialDaysReducer);

    const multipleDays = differenceInDays(endDate, startDate) > 0;

    useEffect(() => {
        if (startDate && endDate) {
            const prevDates = prevDatesRef.current;
            if (
                !prevDates
                || prevDates.startDate.getTime() !== startDate.getTime()
                || prevDates.endDate.getTime() !== endDate.getTime()
            ) {
                prevDatesRef.current = { startDate, endDate };
                dispatch(fetchSpecialDays(
                    addMinutes(startOfDay(startDate), -1),
                    addDays(startOfDay(endDate), 1),
                ));
            }
        }
    }, [startDate, endDate]);

    if (!specialDays.length || isLoading) {
        return null;
    }

    return (
        <SpecialDaysList
            specialDays={specialDays}
            multipleDays={multipleDays}
            className={className}
        />
    );
};
