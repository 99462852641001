import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../@config/AsyncReduxState';
import { SpecialDay } from '../../entities/SpecialDay/SpecialDay'

export type SpecialDaysState = AsyncReduxState<{
    specialDays: SpecialDay[];
}>;

const initialState: SpecialDaysState = {
    ...initialAsyncReduxState,
    specialDays: [],
};

export const specialDaysSlice = createSlice({
    name: 'specialDaysReducer',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): SpecialDaysState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SpecialDaysState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSpecialDays(state, action: PayloadAction<SpecialDay[]>): SpecialDaysState {
            return {
                ...state,
                specialDays: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setSpecialDays,
} = specialDaysSlice.actions;

export default specialDaysSlice.reducer;
